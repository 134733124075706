<template>
  <pageBack>
    <div class="logo"></div>
    <div class="title">提交成功</div>
    <div class="small_text">
      <div>感谢您注册FunPinPin，我们已经收到了您的资料。</div>
      <div>
        审核结果将在近期内审核并且通过邮件进行通知，请留意收件箱。
      </div>
    </div>
    <div class="goHome" @click="toHome">返回首页</div>
  </pageBack>
</template>
<script>
import pageBack from "@/components/basic/pageBack.vue";
export default {
  data() {
    return {};
  },
  methods: {
    toHome() {
      window.location.href =location.origin
    },
  },
  components: { pageBack },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.logo {
  width: 188px;
  height: 196px;
  margin: 71px auto 0;
  background-image: url("../../../assets/images/register/icon02.png");
  background-size: 100% 100%;
}
.title {
  margin-top: 40px;
  font-size: 32px;
  font-weight: 550;
  color: $black;
  line-height: 45px;
  text-align: center;
}
.small_text {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
  color: $black;
  text-align: center;
  line-height: 28px;
}
.goHome {
  width: 160px;
  height: 52px;
  margin: 30px auto 70px;
  text-align: center;
  line-height: 52px;
  background: $green;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  color: $fff;
  cursor: pointer;
}
</style>
